// extracted by mini-css-extract-plugin
export var footerContainer = "footer-module--footerContainer --V2W70";
export var footerUl = "footer-module--footerUl--YHGv3";
export var footerLi = "footer-module--footerLi--vKtvQ";
export var socialLogo = "footer-module--socialLogo--Q7TC9";
export var link = "footer-module--link--CK31r";
export var socialLink = "footer-module--socialLink--PNr+d";
export var socialLogoTwitter = "footer-module--socialLogoTwitter--td2Xv";
export var socialLogoYoutube = "footer-module--socialLogoYoutube--0c57+";
export var socialLogoRSS = "footer-module--socialLogoRSS--Ada-Z";
export var featured = "footer-module--featured--0KRjJ";
export var techBlogsLogo = "footer-module--techBlogsLogo--Poupl";
export var copyright = "footer-module--copyright--Q+Xvw";
export var footerP = "footer-module--footerP--J4zFu";
export var profileImg = "footer-module--profileImg--YzT+2";
export var infoContainer = "footer-module--infoContainer--FL7cL";
export var footerHeader = "footer-module--footerHeader--oISoL";
export var infoText = "footer-module--infoText--VIyyD";
export var socialLogoContainer = "footer-module--socialLogoContainer--Q+5TX";
export var logoContainer = "footer-module--logoContainer--q7LWc";
export var logoWD = "footer-module--logoWD--tDKt+";
export var socialContainer = "footer-module--socialContainer--tA5xN";