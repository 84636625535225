import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import Logo from "../assets/wdtwitter.png";
import Home from "../assets/home.svg";

import Sun from "../assets/sun.svg";
import Moon from "../assets/moon.svg";

import * as headerStyles from "../styles/header.module.css";

const Header = props => {
  const [mobilenav, setMobileNav] = useState(false);
  const [logohome, setLogohome] = useState(Logo);
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    if (isDark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [, isDark]);

  const showLogoHome = () => {
    if (window.location.pathname == "/") {
      return;
    } else {
      logohome == Home ? setLogohome(Logo) : setLogohome(Home);
    }
  };
  const showLogo = () => {
    setLogohome(Logo);
  };

  return (
    <div>
      <div className={`${headerStyles.logoContainer} ${headerStyles.dark}`}>
        <Link to="/" className={headerStyles.logolink}>
          <span className={headerStyles.logoh1}>Webdevolution</span>
          {/* <h2 className={headerStyles.headerh2}>Web Development Blog</h2> */}
        </Link>
      </div>
      <div className={headerStyles.navContainer}>
        <div className={headerStyles.innerNavContainer}>
          <nav className={headerStyles.navHeader}>
            <Link
              className={headerStyles.navLinkHeader}
              activeClassName={headerStyles.activeLinkHeader}
              to={"/"}
            >
              home
            </Link>
            <Link
              className={headerStyles.navLinkHeader}
              activeClassName={headerStyles.activeLinkHeader}
              to={"/blog"}
            >
              blog
            </Link>
            <Link
              className={headerStyles.navLinkHeader}
              activeClassName={headerStyles.activeLinkHeader}
              to={"/tools"}
            >
              tools
            </Link>
          </nav>
          <div className={headerStyles.socialsContainer}>
            {isDark ? (
              <img
                className={headerStyles.darkModeBtn}
                onClick={() => setIsDark(!isDark)}
                src={Sun}
                alt="Light theme"
              ></img>
            ) : (
              <img
                className={headerStyles.darkModeBtn}
                onClick={() => setIsDark(!isDark)}
                src={Moon}
                alt="Dark theme"
              ></img>
            )}
            <a
              className={headerStyles.socialLink}
              href="https://twitter.com/webdevolution_"
            >
              <span
                className={headerStyles.socialLogoTwitter}
                alt="Twitter logo"
              >
                {" "}
              </span>
            </a>
            <a className={headerStyles.socialLink} href="/blog/rss-feed.xml">
              <span className={headerStyles.socialLogoRSS} alt="RSS Feed logo">
                {" "}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
