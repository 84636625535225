// extracted by mini-css-extract-plugin
export var alignCenter = "header-module--alignCenter--Cu1Be";
export var mainHeader = "header-module--mainHeader--xoWFu";
export var secondaryHeader = "header-module--secondaryHeader--yJr1i";
export var divider = "header-module--divider--QC7sK";
export var navContainer = "header-module--navContainer--OBktr";
export var innerNavContainer = "header-module--innerNavContainer--L7oMd";
export var navLinkHeader = "header-module--navLinkHeader---cnyX";
export var navHeader = "header-module--navHeader--EAjR7";
export var darkModeBtn = "header-module--darkModeBtn--KGi4X";
export var menuList = "header-module--menuList--svA+V";
export var menu = "header-module--menu--CYSe2";
export var headerContainer = "header-module--headerContainer--hV2Dm";
export var mobileNavContainer = "header-module--mobileNavContainer--9zYln";
export var link = "header-module--link--qTn+Q";
export var active = "header-module--active--bmKsl";
export var mobileNavButtonLines = "header-module--mobileNavButtonLines--PhOZb";
export var mobileNavButton = "header-module--mobileNavButton--zKY1B";
export var mobileNavigation = "header-module--mobileNavigation--SGUjY";
export var example = "header-module--example--Gpu2S";
export var logo = "header-module--logo--2EQRp";
export var activeLinkHeader = "header-module--activeLinkHeader--jeKQ6";
export var logoContainer = "header-module--logoContainer--Yj7eL";
export var logolink = "header-module--logolink--OO5t5";
export var logoh1 = "header-module--logoh1--OmgZP";
export var headerh2 = "header-module--headerh2--2R8CJ";
export var socialLink = "header-module--socialLink--P8yHv";
export var socialLogoTwitter = "header-module--socialLogoTwitter--NCaCA";
export var socialLogoYoutube = "header-module--socialLogoYoutube--vrm+w";
export var socialLogoRSS = "header-module--socialLogoRSS--3m-P1";
export var socialsContainer = "header-module--socialsContainer--Cuzip";